import React from 'react'

export const Footer = () => {
  return (
    <div>
        <section className='py-5 footerStyled'>
    <div className='container m-auto flex px-5'>

        <div className='w-full'>
            <div className='footer-content'>
            <div className='w-full mb-4'>
            <img className='lg:w-20 md:w-16 w-14 ' src='img/home-page-img/logo.svg'/>
            </div>
            <div className='menus-head w-full flex'>
                <div className='blank-menu w-2/12 lg:block hidden'>

                </div>
                <div className='lg:w-10/12 w-full flex flex-wrap'>
                <div className='menu-1  md:w-2/6 w-full'>
                <h5 className='mb-5 text-xl font-bold'>COMPANY</h5>
                <p className='mb-5'><a href=''>About</a></p>
            
            </div>
            <div className='menu-2  md:w-2/6 w-full'>
                <h5 className='mb-5 text-xl font-bold'>COMMUNITIE</h5>
                <p className='mb-5'><a href=''>For Artists</a></p>
                
                <p className='mb-5'><a href=''>Advertising</a></p>
              
            </div>
            <div className='menu-3  md:w-2/6 w-full'>
                <h5 className='mb-5 text-xl font-bold'>USEFUL LINKS</h5>
                <p className='mb-5'><a href=''>Web Player</a></p>
            </div>
            
                </div>
                <div className='blank-menu w-1/12 lg:block hidden'>

                </div> 
                 </div>
            
                <div className='footer-social-links flex justify-between items-center mb-5'>
                    <div className='left-icon flex items-center gap-6'>
                        <a href=''><img src='img/home-page-img/Twitter.svg'/></a>
                        <a href=''><img src='img/home-page-img/Facebook.svg'/></a>
                        <a href=''><img src='img/home-page-img/Instagram.svg'/></a>
                    </div>
                    <div className='right-icon'>
                        <a href=''><img src='img/home-page-img/africa.svg'/></a>
                    </div>
                </div>
                
            </div>
            
        </div>
        
    </div>
    <div className='copyright bg-black py-5'>
                    <p className='text-white text-center'>© 2022 AfroMelodies</p>
                </div>
</section>

    </div>
  )
}
