import React ,{ useState } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const Signup = () => {

  const navigate = useNavigate();
  const [name, setName] = useState();
  const [resp, setResp] = useState();
  const handleNameChange = (e) => {
    setName(e.target.value)
  };
  const [email, setEmail] = useState();
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  };
  const [password, setPassword] = useState();
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("https://afromelodies.com/api/auth/register", {
        name: (name),
        email: (email),
        password: (password),
        device_type:"Web",
        device_token:"asdajkshd53746jsdfhksjdfhjshdf8we7r9",
        fcm_token:"xcdgfdfgfgddhdh",
      })
      
      .then((res) => {
        setResp(res);
        navigate('/signin');
        
      })
      
      .catch(function (error) {
        if (error.response) {

        } else if (error.request) {

          console.log(error.request);
        } else {

          console.log('Error', error.message);
        }
      });
  };



  const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };


  return (
    <div>
        <section className='py-12'>
            <div className='container px-5 m-auto flex'>

                <div className='w-full'>
                    <div className='lg:w-2/4 md:w-4/5 w-11/12  w-full m-auto hero-content text-center'>
                        <Link to='/'><img className='m-auto mb-5' src='img/home-page-img/logo.svg'/></Link>
                        
                        <hr style={{height:"5px",maxWidth:"600px", margin:"auto",background:"#1ED760",marginBottom:"40px"}}/>
                        <h4 className='lg:mb-5 md:mb-3 mb-2 lg:text-4xl md:text-3xl text-2xl'>Sign up for free to start listening.</h4>

                        <div className=' rounded-3xl lg:p-12 md:p-10 p-6 mb-12' style={{boxShadow: "0px 0px 39px 6px #C4C4C4"}}>
                            <form className='text-left'>
                            <input type="mail" class="lg:text-2xl md:text-xl text-base mt-0 mb-5  lg:py-2 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Enter your email"  onKeyUp={handleEmailChange}/>
                  <input type="mail" class="lg:text-2xl md:text-xl text-base mt-0 mb-5  lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Confirm your email again"/>
                                <input type="password" class="lg:text-2xl md:text-xl text-base mt-0 mb-5  lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Create a password" onKeyUp={handlePasswordChange}/>
                  <input type="text" class="lg:text-2xl md:text-xl text-base mt-0 mb-5  lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Enter a profile name" onKeyUp={handleNameChange}/>
                  <p className='lg:text-2xl md:text-xl text-base mb-5'>What's your date of birth?</p>
                  <input type="date" class="lg:text-2xl md:text-xl text-base mt-0 mb-5  lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder=""   onChange={handleChange} />
                    
                            <p className='lg:text-2xl md:text-xl text-base mb-8'>What's your gender?</p>

                        <div className='radio-head  mb-5 flex flex-wrap justify-between'>
                        <label class="inline-flex mb-4 items-center">
                        <input type="radio" name='gender' value="male"   class="w-6 h-6 border-gray-300 border-2 text-black focus:border-gray-300 focus:ring-black
                        "/>
                      <span class="ml-2 lg:text-xl md:text-base text-sm text-gray-500">Male</span>
                    </label>
                    <label class="inline-flex mb-4 items-center">
                        <input type="radio" name='gender' value="female"   class="w-6 h-6 border-gray-300 border-2 text-black focus:border-gray-300 focus:ring-black
                        "/>
                      <span class="ml-2 lg:text-xl md:text-base text-sm text-gray-500">Female</span>
                    </label>
                    <label class="inline-flex mb-4 items-center">
                        <input type="radio" name='gender' value="Non-binary"    class="w-6 h-6 border-gray-300 border-2 text-black focus:border-gray-300 focus:ring-black
                        "/>
                      <span class="ml-2 lg:text-xl md:text-base text-sm text-gray-500">Non-binary</span>
                    </label>
                   
                        </div>

                        <div className='signup-checkbox-head mb-5'>
                        <label class="flex items-start gap-5 mb-5 ">
                        <input type="checkbox" style={{width:"34px",height:"34px"}} class="mt-2 border-gray-300 border-2 text-black focus:border-gray-300 focus:ring-black
                        "/>
                      <span class="ml-2 block lg:text-xl md:text-base text-sm text-gray-500">I would prefer not to receive marketing messages from AfroMelodies.</span>
                    </label>
                    <label class="flex items-start gap-5 mb-5 ">
                        <input type="checkbox" style={{width:"42px",height:"34px"}} class="mt-2 border-gray-300 border-2 text-black focus:border-gray-300 focus:ring-black
                        "/>
                      <span class="ml-2 block lg:text-xl md:text-base text-sm text-gray-500">Share my registration data with AfroMelodies content providers for marketing purposes.</span>
                    </label>
                    </div>
                    <p className='lg:text-xl md:text-base text-sm mb-5 text-gray-500'>By clicking on sign-up, you agree to AfroMelodies <a href='' className='underline' style={{color:"#1ED760"}}>Terms and Conditions of Use.</a></p>
                    <p className='lg:text-xl md:text-base text-sm mb-5 text-gray-500'>To learn more about how AfroMelodies collects, uses, shares and protects your personal data, please see <a className='underline' href=''style={{color:"#1ED760"}}>AfroMelodies Privacy Policy.</a></p>
                    <div className='text-center mt-12'>
                    <button className='py-3 px-16 rounded-full lg:text-xl text-base text-white inline-block' onClick={(e)=>handleSubmit(e)} style={{background:"#1ED760"}} href=''>SIGN UP</button>
                    </div>
                            </form>

                        </div>

                       
                        <div className='login-back-head'>
                            <p className='lg:text-xl md:text-base text-sm font-bold '>Have an account?<Link to='/signin' style={{color:"#1ED760",marginLeft:"15px"}} >Login</Link></p>
                           
                        </div>
               
                    </div>
                </div>
                
            </div>
        </section>

    </div>
  )
}
