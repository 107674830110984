
import React, { useEffect, useState,useContext} from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import axios from "axios";


import { useLocation } from 'react-router-dom';
import demo from '../../images2/demo.jpg'
import CallPlayer from "../../playerContext/callPlayer";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import PlayerContext from "../../playerContext/playerContext";

export default function Player(props) {
    const playerctx= useContext(PlayerContext)
    let autoplay=true
   const token=playerctx.token
   const userId=localStorage.getItem('userID')

     console.log("HERyusjhsdfhksjdhfkshkdfjhskd",props);
    const [currentMusic, setCurrentMusic] = useState(props.music);
    const [heart, setheart]= useState('empty');
    const [songs,setSongs]=useState(true)
    const location = useLocation

    
    const handleClickPrevious = () => {
      
        if(props.index===0 || props.index===undefined){
            setSongs(false)
        // alert('no more songs')
        return
        }
        setSongs(true)
        props.setIndex(props.index-1)
        props.setPlaying(props.index-1)
        setCurrentMusic(props.allsongs[props.index])
    };

 
    const handleClickNext = () => {
       if(props.index+1===props.allsongs.length || props.index===undefined){
      setSongs(false)
        return
       }
       setSongs(true)
        props.setIndex(props.index+1)
        props.setPlaying(props.index+1)
        setCurrentMusic(props.allsongs[props.index])
    };
    const songadd = async (user) => {
        console.log('tabledataaaaaaaa',user)
        const response = await axios.post('https://afromelodies.com/api/auth/user_song_like',user,{
         headers: { "Authorization": `Bearer ${token}` }} );
      console.log('tabledata',response.data)
        
      }
      const songremove = async (user) => {
        console.log('tabledataaaaaaaa',user)
        const response = await axios.post('https://afromelodies.com/api/auth/user_song_unlike',user,{
         headers: { "Authorization": `Bearer ${token}` }} );
      console.log('tabledataaaaaaaa',response.data)
        
      }
    
    const handlelike=(song)=>{
        setheart('filled')
        const user={
            userID:userId,
            songID:song.id
        }
        console.log('tabledata',song)
        console.log('tabledata',userId,song.id)
        songadd(user)
        
       

    };
    const handleunlike=(song)=>{
        setheart('empty')
        const user={
            userID:userId,
            songID:song.id
        }
        songremove(user)
    };
    
    // const condition=props.allsongs[props.index].song_image;
    console.log("eegtetgedrtgeg",props)
    
    
    
    
    var img,audiosrc,header,like;
   
    if(props.index===undefined ){
     img=props.allsongs.song_image
      audiosrc=props.allsongs.song
      console.log('aaaaaaaaaa')
      header=props.allsongs.name
      like=props.allsongs
    
    }
    else {
 
     img=props.allsongs[props.index].song_image
     audiosrc=props.allsongs[props.index].song
        // show= <CallPlayer music={props.allsongs}  />
         header=props.allsongs[props.index].name
         like=props.allsongs[props.index]
        autoplay=false
    }
    return(
    
        
            <div className="fixed bottom-0 bg-white left-0 right-0" maxWidth="sm">
      {audiosrc &&   <div className="flex px-4 py-4 items-center " style={{ borderRadius: "1rem",boxShadow: "0 0 3px 0 rgb(0 0 0 / 20%)"}}>
    <div className="flex items-center img_sec_fr_plyr w-24 h-24">
       <img style={{ width: "100%", height: "100%" }} src={`https://afromelodies.com/storage/song/images/${img}`} />
    </div>
{console.log("sfcdsfcds",`https://afromelodies.com/storage/song/${audiosrc}`)} 
{!songs &&  <Tooltip
        title="No more songs"
        placement="top"
      >
        <Button></Button>
      </Tooltip>}
     <AudioPlayer
        
       autoPlay={autoplay}
       autoPlayAfterSrcChange={true}
        src={`https://afromelodies.com/storage/song/${audiosrc}`}
        onPlay={(e) => playerctx.play(e)}
        onPause={(e)=> playerctx.pause(e)}
        showSkipControls={true}
        showJumpControls={false}
        header={`Now playing: ${header}`}
        onClickPrevious={handleClickPrevious}
        onClickNext={handleClickNext}
        onEnded={handleClickNext}
        
    />
    
    {
        heart=='empty'?
    <button style ={{cursor:'pointer'}}  onClick={(e)=>handlelike(like)}>
        <img className="w-12 h-12" src="/img/playerIcon/unlike.svg"/>
    </button>:<button style ={{cursor:'pointer'}}  onClick={(e)=>handleunlike(like)}>
    <img className="w-12 h-12" src="/img/playerIcon/like.svg"/>
   
    </button>
}
</div>}
</div>


    
     
        
    );
}
