import React, { useState } from "react";

import axios from 'axios';
import { Details } from "@material-ui/icons";


const PlayerContext =React.createContext({
    token:'',
    playlist:[],
    playerStatus:false,
    musicList:[],
    start:(id)=>{},
    stop:()=>{},
    play:()=>{},
    pause:()=>{},
    isloggedIn:false,
    login:(token)=>{},
    logout:()=>{},
    userId:''
   
  });

  export const PlayerContextProvider=(props)=>{
    const initialToken=localStorage.getItem('token');
    const initialUserId=localStorage.getItem('userID')
    const[token,setToken] = useState(initialToken);
    const[userId,setUserId]=useState(initialUserId)
    const[playerStatus,runPlayerStatus] = useState(false);
   const [musicList , setMusicList]=useState([]);
   
   const userIsLoggedIn= !!token
   const startPlayer=(id,url)=>{
      
      Details(id,url)
}
const Details = async(id,url) => {
    let count=0
    let token=localStorage.getItem('token')

    const res = await axios.get(url,{ headers: { "Authorization": `Bearer ${token}` } });

  
   
   var song= res.data.song?res.data.song:res.data.Message
  song= res.data.data?res.data.data:song
   console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',song,id)
   if (!song){
    alert('no songs available')
    return
    }
  
   song.map((element) => {
       
        if(id===element.id){
            setMusicList(element)
            console.log(element)
            runPlayerStatus(true)
            count=1
        }
    });  
   
    
  console.log('aaaaaaaa',musicList)
  if(count===0)
  alert('no songs found')
}
const play=()=>{
    runPlayerStatus(true)
    console.log('aaaaaaaaaaaaaaaaaaaaplay')
}
const pause=()=>{
    runPlayerStatus(false)
    console.log('aaaaaaaaaaaaaaaaaaaapause')

}
const login=(token)=>{
    setToken(token);
    localStorage.setItem('token', token)
    getId(token)
    
}
const getId= async(token) => {
     
        
        console.log('userid',token)
   
    const res = await axios.post(`https://afromelodies.com/api/auth/me`,'',{headers: { "Authorization": `Bearer ${token}`}});
   
      console.log('dshgjfgjsdgfjhsdgfjhsgdfjhgd',res)
    localStorage.setItem("userID",res.data.data.id)

  
  }

const logout=()=>{
    setToken(null);
    
     localStorage.removeItem('token')
     localStorage.removeItem('userID')
}


   const stopPlayer=()=>{
    runPlayerStatus(false)
}

    const contextValue={
        token:token,
        playerStatus:playerStatus,
        start:startPlayer,
        stop:stopPlayer,
        musicList:musicList,
        play:play,
        pause:pause,
        isloggedIn:userIsLoggedIn,
        login:login,
        logout:logout,
        userId:userId
        
    }  


return (
    <PlayerContext.Provider value={contextValue}>
        {props.children}
    </PlayerContext.Provider>
    )
}
export default PlayerContext;