import React, { Suspense,useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Footer } from './components/Layout/Footer';
import { Header } from './components/Layout/Header';
import PlayerContext from '../src/playerContext/playerContext'
import StartPlayer from './playerContext/StartPlayer';

import './App.css'
import { Signin } from './pages/signin';
import { Signup } from './pages/Signup';
import { Download } from './pages/Download';
import Load from './components/loader/loader';
import SelectedCategory from './pages/selectedCategory';
import Redirectpage from './pages/Redirectpage';
import AddPlaylist from './pages/AddPlaylist';
const Home=React.lazy(()=>import('./pages'))
const Listing=React.lazy(()=>import('./pages/Listing'))
const Artist=React.lazy(() =>import( './components/crousal/Artist'));
const ArtistProfile=React.lazy(() =>import( './pages/ArtistProfile'));
const Songs=React.lazy(() =>import( './pages/Song'));
const ListArtist=React.lazy(() =>import( './components/listing/ListArtist'));
const ListMixSongs=React.lazy(() =>import( './components/listing/ListMixSongs'));
const ListPopularSongs=React.lazy(() =>import( './components/listing/ListPopularSong'));
const ListCatagories=React.lazy(() =>import( './components/listing/ListCatagories'));
const ListBestArtist=React.lazy(() =>import( './components/listing/ListBestArtist'));
const ListLatestSong=React.lazy(() =>import( './components/listing/ListLatestSong'));
const ListFeatureArtist=React.lazy(() =>import( './components/listing/ListFeatureArtist'));
const ListSelectedPlaylist=React.lazy(() =>import( './components/listing/ListSelectedPlaylist'));
const Sidebar=React.lazy(() =>import( './pages/Sidebar'));





function App() {
 
const loginctx=useContext(PlayerContext)
const user=loginctx.isloggedIn

  return (
    <div className="App">
      <Suspense fallback={<Load />}>
				
		
     <Sidebar />
        <Routes>
        
          <Route exact path="/" element={<><Header/><div className='main'><Home /></div> <StartPlayer /></>} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/download" element={<><Header/><div className='main'><Download /></div> <StartPlayer /></>} />
          <Route path="/listing" element={<><Header/><div className='main'><Listing /></div> <StartPlayer /></>} />
          {/* <Route path="/artist" element={<><Header/><div className='main'><Artist/></div> <StartPlayer /></>} /> */}
          <Route path="/artistProfile/:id" element={<><Header/><div className='main'><ArtistProfile/></div> <StartPlayer /></>} />
          <Route path="/category/:id/" element={<><Header/><div className='main'><SelectedCategory/></div> </>} />
          <Route path="/song" element={<><Header/><div className='main'><Songs/></div> <StartPlayer /></>} />
          {/* <Route path="/addplaylist" element={<><Header/><div className='main'><AddPlaylist/> </div> <StartPlayer /></>} />
     */}
          <Route path="/artist" element={<><Header/><div className='main'><ListArtist/></div> <StartPlayer /></>} />
          {/* <Route path="/artist" element={<><Header/><div className='main'><Redirectpage /></div> <StartPlayer /></>} /> */}
          <Route path="/mixsongs" element={<><Header/><div className='main'><ListMixSongs/></div> <StartPlayer /></>} />
          <Route path="/popularsongs" element={<><Header/><div className='main'><ListPopularSongs/></div> <StartPlayer /></>} />
          <Route path="/category" element={<><Header/><div className='main'><ListCatagories/></div> <StartPlayer /></>} />
          <Route path="/bestartist" element={<><Header/><div className='main'><ListBestArtist/></div> <StartPlayer /></>} />
          <Route path="/latestSong" element={<><Header/><div className='main'><ListLatestSong/></div> <StartPlayer /></>} />
          <Route path="/featureArtist" element={<><Header/><div className='main'><ListFeatureArtist/></div> <StartPlayer /></>} />
          <Route path="/playlist" element={<><Header/><div className='main'><ListSelectedPlaylist/><Footer /></div> <StartPlayer /></>} />
          

       

      </Routes>
    
      </Suspense>     
    
    </div>
  );
}

export default App;
