import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { GoogleLogin } from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import validator from 'validator'
import { Button } from '@mui/material';
import PlayerContext from '../playerContext/playerContext';

export const Signin = () => {
const loginCtx=useContext(PlayerContext)

  const [success, setSuccess] = useState(null);

  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  };
console.log(email)
const checkData = localStorage.getItem('token');
if(checkData!=null){
  window.location.href = '/';
}
const [emailError, setEmailError] = useState('')
const validateEmail = (e) => {
  var email = e.target.value

  if (validator.isEmail(email)) {
    setEmailError('')
  } else {
    setEmailError('Enter valid Email!')
  }
}

  const [password, setPassword] = useState();
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  };
  console.log(password)

  const [errorMessage, setErrorMessage] = useState('')
  
  const validate = (value) => {
  
    if (validator.isStrongPassword(value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) {
      setErrorMessage('')
    } else {
      setErrorMessage('Is Not Strong Password')
    }
  }


  const handleSubmit = (e) => {
  

    e.preventDefault();
    console.log(email)
    console.log(password)
    axios.post("https://afromelodies.com/api/auth/login", {
      email: (email)
,
      password: (password),
      device_type:"Web",
      device_token:"asdajkshd53746jsdfhksjdfhjshdf8we7r9",
      fcm_token:"xcdgfdfgfgddhdh",

    })

      .then((res) => {
        console.log("login",res)
     
//  localStorage.setItem('token', res.data.token);
loginCtx.login(res.data.token)
  navigate('/');
 console.log("login",res.data)
 

       
        // if (res.data.status === 'success') {
       
        //   const data = parseJwt(res.data.access);
        //   localStorage.setItem('auth_token', res.data.access);
        //   localStorage.setItem('auth_email', data.email);
        //   console.log("test",res.data.status)
        //   if (res.data.status === 200) {
         
        //     navigate('/');
        
        //   }
        //   else {
        //     navigate("/signin");

       
        //   };
        // }

      }).catch((error) => {
        // hdfjfhdskhfs(error.detail)
      })

  };
  const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    // console.log(jsonPayload)
    return JSON.parse(jsonPayload);
  };

  const responseGoogle = (response) => {
    console.log(response);
  }


    return (
        <div>
            <section className='py-8'>
                <div className='container m-auto flex'>

                    <div className='w-full'>
                        <div className='lg:w-2/4 md:w-4/5 w-11/12 m-auto hero-content text-center'>
                          <div className='w-9/12 m-auto'>
                          <Link to='/'> <img className='m-auto w-20 mb-5' src='img/home-page-img/logo.svg' /></Link>
                            <h1 className='lg:mb-5 md:mb-3 mb-2 lg:text-2xl md:text-xl text-base'>Free Somali Songs On AfroMelodies</h1>
                            <hr style={{ height: "5px", maxWidth: "600px", margin: "auto", background: "#1ED760", marginBottom: "20px" }} />
                            <h4 className='lg:mb-3 md:mb-3 mb-2 lg:text-base  text-sm'>To continue, log in to AfroMelodies.</h4>

                      <div className='w-6/6 m-auto'>
                        <div className='social-links text-left'>
                        

                            <Link to className="flex lg:text-xl md:text-base text-sm items-center bg-black text-white  justify-center m-auto mb-5 py-2 lg:px-12 md:px-8 px-5 rounded-full text-white"><img className='mr-5 w-6' src='img/home-page-img/fb.png' /> Continue with Facebook</Link>
                            <Link to className='flex lg:text-xl md:text-base text-sm items-center bg-black text-white justify-center m-auto mb-5 py-2 lg:px-12 md:px-8 px-5 rounded-full text-white ' href=''><img className='mr-5 w-6' src='img/home-page-img/google.png' />Continue with Google</Link>
                         {/* <GoogleLogin
                            clientId="496574926292-p8ih2pd4galemf4sbstag76hmmv2ptu1.apps.googleusercontent.com"
                            buttonText="Continue with Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                         /> */}

                            <Link to className='flex lg:text-xl md:text-base text-sm items-center bg-black text-white  justify-center m-auto mb-5 py-2 lg:px-12 md:px-8 px-5 rounded-full text-white ' href=''><img className='mr-5 h-6' src='img/home-page-img/apple.png' />Continue with Apple</Link>
                           
                            <div className='or-head flex items-center '>
                            <hr style={{ height: "1px", width: "100px", marginLeft: "auto", background: "#5B5B5B", marginRight: "10px" }} /><p className='text-base'>OR</p><hr style={{ height: "1px", width: "100px", marginRight: "auto", background: "#5B5B5B", marginLeft: "10px" }} />
                            </div>
                        </div>
                        </div>
                          </div>
                            
                            <div className=' lg:p-5 md:p-4 p-5 mb-8 w-4/5 m-auto'>
                                <form className='text-left' >
                                    <input type="mail" className="focus-visible:none focus:outline-none mt-0 pl-4 mb-5 py-4 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                                      " placeholder="Johndoe@maipl.com" onChange={(e) => validateEmail(e)} onKeyUp={handleEmailChange}/>
                                    <input type="password" className="focus:outline-none mt-0 pl-4 mb-5 py-4 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                                      " placeholder="Password"  onKeyUp={handlePasswordChange}/>
                            <Link to className='lg:text-xl md:text-base text-sm decoration-0 inline-block underline mb-12' style={{color: "#1B1B1B"}} href=''>Forgot password?</Link>

                                <div className='checkbox-head flex justify-between'>
                                    <label className="inline-flex items-center">
                                        <input type="checkbox" className="border-gray-300 border-2 text-black focus:border-gray-300 focus:ring-black"/>
                                        <span className="ml-2 lg:text-xl md:text-base text-sm">Remember me</span>
                                    </label>
                                    <button className='lg:py-3 py-2 lg:px-12 px-8 p rounded-full text-white inline-block' onClick={(e)=>handleSubmit(e)} style={{ background: "#1ED760" }} >LOG IN</button>
                                </div>
                            </form>

                        </div>

                       
                        <div className='w-5/6 m-auto'>
                        <div className='social-links text-left'>  
                            <p className='lg:text-xl md:text-base text-sm items-center font-bold  text-black text-center mb-5'>Don't have an account?</p>
                            <Link to = '/signup' className='block lg:text-xl md:text-base text-sm items-center text-gray-900 uppercase font-normal text-center lg:w-4/5 w-11/12 m-auto mb-5 bg-white rounded-full border-2 border-slate-900 border-solid py-3 lg:px-12 md:px-8 px-5' href=''>Sign up for AfroMelodies.</Link>
                        </div>
                        </div>
                        

                        <div className='signup-head'>

                        </div>
                   
                    </div>
                </div>

            </div>
        </section >


    </div >
  )
}
