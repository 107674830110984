import React, { useContext} from 'react'
import PlayerContext from './playerContext'
import Player from '../components/Musicplayer/player1'
  


const StartPlayer = (props) => {

    const playerctx=useContext(PlayerContext)
   var play;
    const user=playerctx.isloggedIn
   if(props.index===undefined ){
   if(playerctx.musicList){
   play=<Player allsongs={playerctx.musicList} />
   
   }else{
   play=''
   }
   }
   else{
   play=<Player allsongs={props.allsongs} setPlaying={props.setPlaying} setIndex={props.setIndex}  index={props.index}/> 
   }
    
 console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaa',playerctx.musicList)
  return (
   <div>{play}</div>
  )
}

export default StartPlayer